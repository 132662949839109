<template>
  <div>
    <el-dialog title="专属资料" :visible.sync="materialVisible" width="660px" :before-close="materialClose">
      <div class="material">
        <!-- <div>
          <el-radio-group v-model="radio" @change="radioChange">
            <el-radio :label="1">资料包</el-radio>
            <el-radio :label="2">资料</el-radio>
          </el-radio-group>
        </div> -->
        <div class="materialWrap">
          <template v-for="i in docPackage">
            <el-collapse accordion :key="i.id" @change="getPackageDetails(i)">
              <el-collapse-item class="collapse-item">
                <template slot="title">
                  <div class="materialItem" :key="i.id">
                    <div class="itemBox">
                      <img src="@/assets/img/study/fileIcon.png" class="docPackageImg" />
                      <div class="contentWrap">
                        <div class="contentTitle twoEllipsis">
                          {{ i.typeName }}
                        </div>
                        <div class="contentTag">
                          共{{ i.subjectNum ? i.subjectNum : "0" }}份资料
                        </div>
                      </div>
                      <!-- <div v-if="IsPurchase" class="btn" @click="checkClick(i)">查看资料</div> -->
                    </div>
                  </div>
                </template>
                <div class="sourceItem" v-for="item in packageList" :key="item.id">
                  <div class="sourceImg">
                    <div v-if="IsPurchase" class="seeBtn" @click="examination(item)"></div>
                    <img v-if="item.filetype == 'excel'" class="imgItem" src="@/assets/img/study/dataXls.png" />
                    <img v-else-if="item.filetype == 'word'" class="imgItem" src="@/assets/img/study/dataDoc.png" />
                    <img v-else-if="item.filetype == 'pdf'" class="imgItem" src="@/assets/img/study/dataPdf.png" />
                    <img v-else-if="item.filetype == 'ppt'" class="imgItem" src="@/assets/img/study/dataPpt.png" />
                    <img v-else class="imgItem" src="@/assets/img/study/dataFile.png" />
                  </div>
                  <div class="contentWrap">
                    <div class="contentTitle twoEllipsis">{{ item.title }}</div>
                    <div class="size">
                      {{
                        item.size
                        ? item.size == "NaNk"
                          ? "0k"
                          : item.size
                        : "0k"
                      }}
                    </div>
                  </div>
                  <div class="kowledge_btn" @click="openKowledge(item.mid)">
                    知识点
                  </div>
                  <div v-if="IsPurchase" class="btn" @click="dowInfo(item)">
                    下载资料
                  </div>
                  <div v-if="!IsPurchase && item.seeNum && item.seeNum > 0" class="btn" @click="examination(item)">
                    免费试看
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </template>
          <template v-for="item in docList">
            <div class="materialItem" :key="item.id">
              <div class="imgWrap">
                <div v-if="IsPurchase" class="seeBtn" @click="examination(item)"></div>
                <img v-if="item.filetype == 'excel'" class="imgItem" src="@/assets/img/study/dataXls.png" />
                <img v-else-if="item.filetype == 'word'" class="imgItem" src="@/assets/img/study/dataDoc.png" />
                <img v-else-if="item.filetype == 'pdf'" class="imgItem" src="@/assets/img/study/dataPdf.png" />
                <img v-else-if="item.filetype == 'ppt'" class="imgItem" src="@/assets/img/study/dataPpt.png" />
                <img v-else class="imgItem" src="@/assets/img/study/dataFile.png" />
              </div>
              <div class="contentWrap">
                <div class="contentTitle twoEllipsis">{{ item.typeName }}</div>
                <div class="size">
                  {{
                    item.size ? (item.size == "NaNk" ? "0k" : item.size) : "0k"
                  }}
                </div>
              </div>
              <div v-if="IsPurchase" class="kowledge_btn" @click="openKowledge(item.typeId ? item.typeId : item.mid)">
                知识点
              </div>
              <div v-if="IsPurchase" class="btn" @click="dowInfo(item)">
                下载资料
              </div>
              <div v-if="!IsPurchase && item.seeNum && item.seeNum > 0 && userInfo" class="btn"
                @click="examination(item)">
                免费试看
              </div>
            </div>
            <el-divider :key="item.typeId"></el-divider>
          </template>
          <div class="noMsg" v-if="docList.length < 1 && docPackage.length < 1">
            暂无内容~
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 知识点 -->
    <el-dialog :visible.sync="kowledgePointsVisible" width="50%" :before-close="kowledgePointsClose" :show-close="false">
      <div class="kowledgePoints_wrap">
        <kowledgePoints ref="kowledgePoints" type="4"></kowledgePoints>
        <div class="determine_btn" @click="kowledgePointsClose">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Know } from "@/api/know";
import { DataClient } from "@/api/dataClient";
import { getInfo } from "@/api/cookies";
let dataClient = new DataClient();
let know = new Know();
import Bus from "@/api/bus";
import Vue from "vue";
import kowledgePoints from "@/components/kowledgePoints.vue";
export default {
  props: ["materialVisible", "classesId", "direction"],
  components: {
    kowledgePoints,
  },
  data() {
    return {
      docPackage: [], // 资料包
      docList: [], // 资料
      userInfo: null,
      IsPurchase: null, // 是否购买
      radio: 1, // 1 资料包  2 资料
      packageList: [], // 资料包下的资料列表
      id: null, // 套餐ID

      kowledgePointsVisible: false,
    };
  },
  /* created() {
    this.getClassesMaterials();
  }, */
  methods: {
    init(id, IsPurchase) {
      this.IsPurchase = IsPurchase;
      this.id = id;
      this.getClassesMaterials(id);
      this.userInfo = getInfo();
    },
    /* 资料、资料包radio */
    radioChange() { },
    /* 专属资料 */
    getClassesMaterials(id) {
      if (this.direction) {
        let that = this;
        let data = {
          classesId: id,
          coursePackageId: this.direction,
        };
        know.getCoursePackageMaterialsList(data).then((res) => {
          for (let item of res.data.docList) {
            item.filetype = Vue.prototype.getFileType(item.url);
            Vue.prototype.getFilesize(item.url, function (size) {
              let sizeVal = (size / 1028).toFixed(0) + "k";
              let data = (size / 1028 / 1024).toFixed(0);
              that.$set(item, "size", data > 0 ? data + "M" : sizeVal);
              /* item.size = data > 0 ? data + "M" : sizeVal; */
              that.$forceUpdate();
            });
          }
          this.docList = res.data.docList;
          this.docPackage = res.data.docPackage;
        });
      } else {
        let that = this;
        know.getClassesMaterialsList(id).then((res) => {
          for (let item of res.data.docList) {
            item.filetype = Vue.prototype.getFileType(item.url);
            Vue.prototype.getFilesize(item.url, function (size) {
              let sizeVal = (size / 1028).toFixed(0) + "k";
              let data = (size / 1028 / 1024).toFixed(0);
              that.$set(item, "size", data > 0 ? data + "M" : sizeVal);
              /* item.size = data > 0 ? data + "M" : sizeVal; */
              that.$forceUpdate();
            });
          }
          this.docList = res.data.docList;
          this.docPackage = res.data.docPackage;
        });
      }
    },

    /* 资料详情 */
    getPackageDetails(i) {
      let that = this;
      if (this.userInfo) {
        dataClient
          .getPackageDetails(10000, 1, i.typeId, this.userInfo.id)
          .then((res) => {
            for (let item of res.rows) {
              item.filetype = Vue.prototype.getFileType(item.names);
              Vue.prototype.getFilesize(item.names, function (size) {
                let sizeVal = (size / 1028).toFixed(0) + "k";
                let data = (size / 1028 / 1024).toFixed(0);
                /* item.size = data > 0 ? data + "M" : sizeVal; */
                that.$set(item, "size", data > 0 ? data + "M" : sizeVal);
                /*  that.$forceUpdate(); */
              });
            }
            this.packageList = res.rows;
          });
      } else {
        Vue.prototype.goLoginView(false);
      }
    },
    /* 下载资料 */
    dowInfo(item) {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (this.IsPurchase && !item.type) {
        if (item.names) {
          let data = {
            zlId: item.mid,
            xmId: item.kind,
            dlId: item.pid,
            linkLocation: item.names,
            name: item.title,
            type: 1,
          };
          dataClient.downloadRecord(data);
          Vue.prototype.DownloadDoc(item.names, item.title);
        } else {
          this.$notify({
            title: "错误",
            message: "暂无资源",
            type: "error",
            duration: 2000,
          });
        }
      } else if (this.IsPurchase && item.type == 4) {
        if (item.url) {
          let data = {
            zlId: item.id,
            xmId: item.kind,
            dlId: item.pId,
            linkLocation: item.url,
            name: item.typeName,
            type: 1,
          };
          dataClient.downloadRecord(data);
          Vue.prototype.DownloadDoc(item.url, item.typeName);
        } else {
          this.$notify({
            title: "错误",
            message: "暂无资源",
            type: "error",
            duration: 2000,
          });
        }
      } else {
        this.$notify({
          title: "错误",
          message: "未购买，无法查看, 请先去购买",
          type: "error",
          duration: 2000,
        });
      }
    },
    // 点击跳转到详情页
    checkClick(item) {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (this.IsPurchase) {
        this.$router.push(
          `/data/dataPackageDetail?packageId=${item.typeId}&classesId=${this.classesId}`
        );
      } else {
        this.$notify({
          title: "错误",
          message: "未购买，无法下载, 请先去购买",
          type: "error",
          duration: 2000,
        });
      }
    },
    /* 免费试看 */
    examination(item) {
      if (this.userInfo) {
        this.$router.push({
          path: "/VIP/sourcePage",
          query: {
            IsPurchase: this.IsPurchase,
            seeNum: item.seeNum,
            names: item.names ? item.names : item.url,
            id: this.id,
            type: 1,
          },
        });
      } else {
        Vue.prototype.goLoginView(false);
      }
    },
    /* 关闭回调 */
    materialClose() {
      this.docList = [];
      this.docPackage = [];
      this.$emit("materialClose");
    },

    // 知识点
    openKowledge(id) {

      this.kowledgePointsVisible = true;
      this.$nextTick(() => {
        this.$refs.kowledgePoints.getData(id);
      });
    },
    kowledgePointsClose() {
      this.kowledgePointsVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.material {
  height: 500px;

  .materialWrap {
    margin-top: 10px;
    margin-right: 12px;
    height: 470px;
    overflow: auto;

    .materialItem {
      display: flex;
      margin-top: 10px;

      .itemBox {
        display: flex;
      }

      .sourceBox {}

      .docPackageImg {
        width: 100px;
        height: 100px;
        margin-right: 12px;
      }

      .imgWrap {
        display: flex;
        width: 100px;
        height: 100px;
        align-items: center;
        justify-content: center;
        background-color: #f5f7f9;
        margin-right: 12px;
        position: relative;

        .imgItem {
          width: 40px;
          height: 40px;
        }

        .seeBtn {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .contentWrap {
        width: 375px;

        .contentTitle {
          line-height: 24px;
          color: #333333;
        }

        .contentTag {
          display: inline-block;
          padding: 4px 8px;
          background-color: #fff2e9;
          color: #ff7b1c;
          border-radius: 2px;
          margin-top: 12px;
        }

        .size {
          font-size: 12px;
          color: #999999;
          margin-top: 12px;
        }
      }

      .btn {
        width: 88px;
        height: 30px !important;
        border: 1px solid #ff5e51;
        border-radius: 4px;
        color: #ff5e51;
        text-align: center;
        line-height: 30px;
        margin-top: 35px;
        cursor: pointer;
      }
    }

    .sourceItem {
      margin-left: 50px;
      display: flex;
      margin-bottom: 10px;

      .sourceImg {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .imgItem {
          width: 40px;
          height: 40px;
        }

        .seeBtn {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .contentWrap {
        width: 375px;

        .contentTitle {
          line-height: 24px;
          color: #333333;
          width: 300px;
          overflow: hidden;
          height: 48px;
        }

        .contentTag {
          display: inline-block;
          padding: 4px 8px;
          background-color: #fff2e9;
          color: #ff7b1c;
          border-radius: 2px;
          margin-top: 12px;
        }

        .size {
          font-size: 12px;
          color: #999999;
          margin-top: 12px;
        }
      }

      .btn {
        width: 70px;
        height: 30px !important;
        border: 1px solid #ff5e51;
        border-radius: 4px;
        color: #ff5e51;
        text-align: center;
        line-height: 30px;
        margin-top: 35px;
        cursor: pointer;
        flex-shrink: 0;
      }
    }
  }
}

.kowledge_btn {
  width: 88px;
  height: 30px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 14px;
  color: #666666;
  line-height: 30px;
  text-align: center;
  margin-top: 35px;
  margin-right: 20px;
  flex-shrink: 0;
  cursor: pointer;
}

.noMsg {
  text-align: center;
  line-height: 400px;
}

.collapse-item {
  height: 500px;
}

.kowledgePoints_wrap {
  padding-bottom: 20px;

  .determine_btn {
    width: 40%;
    height: 40px;
    margin: 0 auto;
    margin-top: 20px;
    background-color: #ff5e51;
    color: #ffffff;
    font-weight: 500;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
}

.materialWrap ::v-deep .el-collapse-item__header {
  height: 140px;
  border: none;
  line-height: 16px;
}

.materialWrap ::v-deep .collapse-item {
  height: auto;
}
</style>
