<template>
  <div>
    <el-dialog title="专属教辅" :visible.sync="bookVisible" width="660px" :before-close="bookClose">
      <div class="textBookWrap">
        <div v-for="(item, index) in textBookList" :key="index" class="textBook">
          <div class="textBookItem">
            <div class="imageWrap">
              <el-image :src="item.faceImg" class="imageItem" />
              <!-- <div class="tabs">可试看</div> -->
            </div>
            <div>
              <div class="titleItem twoEllipsis">{{ item.textbookName }}</div>
              <div class="author">作者：{{ item.teacherName }}</div>
              <div class="sasuke">
                <div class="tag">简介：</div>
                <div class="content" v-html="ImgSizeLimit(item.textBookNote)" />
              </div>
            </div>
            <div class="btn">
              <el-button size="mini" type="" @click="goTextbookDetails(item)">教材详情</el-button>
              <el-button
                v-if="!IsPurchase"
                size="mini"
                type="danger"
                @click="seeTextbook(item)"
              >免费试看</el-button>
              <el-button v-else size="mini" type="danger" @click="seeTextbook(item)">阅读教材</el-button>
            </div>
          </div>
          <el-divider />
        </div>
        <div v-if="textBookList.length == 0" class="nothing">
          <div class="nothing-text">暂无内容哦~</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getClassTeaching, getClassCourseTeaching } from '@/api/home.js'
import { getInfo } from '@/api/cookies'
import Bus from '@/api/bus'
import Vue from 'vue'
export default {
  components: {},
  props: ['bookVisible', 'IsPurchase', 'direction'],
  data() {
    return {
      textBookList: [],
      classesId: null,
      title: null, // 套餐名称

      userInfo: getInfo()
    }
  },
  created() {},
  methods: {
    init(id, IsPurchase, title) {
      this.classesId = id
      this.title = title
      this.getClassTeaching(id)
    },
    /* 教材列表 */
    getClassTeaching(id) {
      if (this.direction) {
        const data = {
          id,
          coursePackageId: this.direction
        }
        getClassCourseTeaching(data).then(res => {
          this.textBookList = res.msg
        })
      } else {
        getClassTeaching(id).then((res) => {
          this.textBookList = res.msg
        })
      }
    },
    /* 点击教材详情 */
    goTextbookDetails(item) {
      this.$router.push({
        path: '/typeclassxq/textbookDetails',
        query: {
          id: item.id,
          title: this.title,
          classesId: this.classesId,
          IsPurchase: this.IsPurchase
        }
      })
    },
    /* 点击阅读/试看教材 */
    seeTextbook(item) {
      const userInfo = getInfo()
      if (userInfo) {
        this.$router.push({
          path: '/VIP/textbookPage',
          query: {
            IsPurchase: this.IsPurchase,
            id: item.id,
            classesId: this.classesId,
            title: this.title
          }
        })
      } else {
        Vue.prototype.goLoginView(false)
      }
    },
    /* 关闭回调 */
    bookClose() {
      this.$emit('bookClose')
    }
  }
}
</script>

<style lang="less" scoped>
    .textBook{
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    .textBook /deep/ .el-divider{
        margin: 0;
        width: 596px;
        background-color: #EEEEEE;
    }
    .textBookWrap{
        background-color: #fff;
        height: 500px;
        overflow: auto;
    }
    .textBookItem{
        display: flex;
        width: 100%;
        height: 192px;
        padding-top: 32px;
        position: relative;
    }
    .imageWrap{
        width: 108px;
        height: 144px;
        position: relative;
        margin-right: 12px;
    }
    .imageItem{
        width: 108px;
        height: 144px;
        border-radius: 8px 8px 8px 8px;

    }
    .tabs{
        width: 52px;
        height: 20px;
        background-color: rgba(255, 94, 81, 1);
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        line-height: 20px;
        border-radius: 8px 0px 8px 0px;
    }
    .titleItem{
      width: 286px;
      color: rgba(51, 51, 51, 1);
    }
    .author{
        margin-top: 16px;
        color: rgba(102, 102, 102, 1);
    }
    .sasuke{
        display: flex;
        height: 60px;
        margin-top: 24px;
        width: 260px;
        font-size: 12px;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
        margin-right: 26px;
        .tag{
          width: 40px;
        }
        .content{
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
    }
    .btn{
        display: flex;
        align-items: center;
        margin-left: 10px;
    }
</style>
