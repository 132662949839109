<template>
  <div>
    <div class="room">
      <el-radio-group class="radioWrap" v-model="radio" @change="yearChange">
        <el-radio v-for="(item, index) in yearList" :key="index" :label="item">{{ item }}</el-radio>
        <!--   <el-radio label="2020">2020</el-radio>
          <el-radio label="2019">2019</el-radio> -->
      </el-radio-group>
      <div class="roomWrap">
        <template v-for="(item, index) in roomList">
          <div class="roomItem" :key="index">
            <img v-if="item.roomStatus !== 1" src="@/assets/img/study/exmRoom.png" class="roomImgItem" />
            <img v-else src="@/assets/img/study/finishExmRoom.png" class="roomImgItem" />
            <div class="roomItemContent">
              <div class="roomItemTitle">
                <div class="roomItemTag" v-if="item.type == 2">限邀请</div>
                <span class="roomItemText twoEllipsis">{{ item.typeName }}</span>
              </div>
              <div class="timeText">{{ item.startTime + "-" + item.endTime }}</div>
              <div class="tips"><!-- {{item.subjectNum}}套试卷, -->共{{ item.subjectNum }}题</div>
            </div>
            <div v-if="item.roomStatus !== 1 && testRoom(item)" class="btnItem" @click="goRoom(item)">
              进入考场
            </div>
          </div>
          <el-divider :key="index"></el-divider>
        </template>
        <div class="noMsg" v-if="roomList.length < 1">暂无内容~</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Know } from "@/api/know";
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
let know = new Know();
export default {
  props: ["roomVisible", "IsPurchase", "classesId", 'direction'],

  data() {
    return {
      radio: "2021",
      roomList: [],
      /*  IsPurchase: null, // 是否购买
      classesId: null, */
      userInfo: null,
      yearList: [],
    };
  },
  created() {
    this.userInfo = getInfo();
    this.virtualTestList()
  },
  methods: {
    /* 选择年份 */
    yearChange() {
      this.selectVirtualRoomPcList();
    },
    testRoom(item) {
      return true;
      /* let startTime = new Date(item.startTime);
      let nowTime = new Date();
      if (startTime.getTime() < nowTime.getTime()) {
        return true;
      } else {
        return false;
      } */
    },
    /* 进入考场 */
    goRoom(item) {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (this.IsPurchase) {
        let sub = {
          roomId: item.typeId,
          classId: this.classesId,
          IsPurchase: this.IsPurchase
        };
        let data = Vue.prototype.export(sub);
        this.$router.push(`/quesTion/examRoom/examRoomDetail?${data}`);
      } else {
        this.$notify({
          title: "错误",
          message: "未购买，无法答题, 请先去购买",
          type: "error",
          duration: 2000,
        });
      }
    },
    expired(item) {

      if (item.roomStatus == 1) {
        this.$message({
          type: "warning",
          message: "考场未开始",
        });
      } else if (item.roomStatus == 3) {
        this.$message({
          type: "warning",
          message: "考场已结束",
        });
      }
    },
    /* 专属考场列表 */
    selectVirtualRoomPcList() {
      if (this.direction) {
        let data = {
          classesId: this.classesId,
          coursePackageId: this.direction,
          yearName: this.radio
        }
        know.selectVirtualRoomPcPackageList(data).then((res) => {
          this.roomList = res.data;
        });
      } else {
        let params = {
          classesId: this.classesId,
          yearName: this.radio,
        };
        know.selectVirtualRoomPcList(params).then((res) => {
          this.roomList = res.data;
        });
      }
    },
    virtualTestList() {
      if (this.direction) {
        let data = {
          classesId: this.classesId,
          coursePackageId: this.direction
        }
        know.selectClassesCourseRoomList(data).then((res) => {
          if (res.code == 0) {
            for (let item of res.data) {
              item.passbai =
                item.passNum && item.passNum != "0" ? ((item.passNum / item.zrNum) * 100).toFixed(2) + "%" : "0%";
              item.year = item.startTime.substring(0, 4);
              this.yearList.push(item.startTime.substring(0, 4));
              this.yearList = Array.from(new Set(this.yearList));
              this.yearList.sort(function (a, b) {
                return b - a;
              });
              this.radio = this.yearList[0];
            }
            this.selectVirtualRoomPcList();
          }
        });
      } else {
        know.selectClassesRoomList(this.classesId).then((res) => {
          if (res.code == 0) {
            for (let item of res.data) {
              item.passbai =
                item.passNum && item.passNum != "0" ? ((item.passNum / item.zrNum) * 100).toFixed(2) + "%" : "0%";
              item.year = item.startTime.substring(0, 4);
              this.yearList.push(item.startTime.substring(0, 4));
              this.yearList = Array.from(new Set(this.yearList));
              this.yearList.sort(function (a, b) {
                return b - a;
              });
              this.radio = this.yearList[0];
            }
            this.selectVirtualRoomPcList();
          }
        });
      }
    },
    /* 关闭回调 */
    roomClose() {
      this.radio = "2021";
      this.IsPurchase = null;
      this.$emit("roomClose");
    },
  },
};
</script>

<style lang="less" scoped>
.room {
  height: 500px;

  .radioWrap {
    margin-bottom: 20px;
  }

  .roomWrap {
    height: 440px;
    overflow: auto;

    .roomItem {
      display: flex;

      .roomImgItem {
        width: 100px;
        height: 100px;
        margin-right: 12px;
      }

      .itemTag {
        width: 38px;
        height: 16px;

        border-radius: 8px 0 12px 0;
      }

      .roomItemContent {
        width: 375px;

        .roomItemTitle {
          .roomItemTag {
            display: inline-block;
            min-width: 34px;
            padding: 3px 6px;
            background-color: #ffeee2;
            font-size: 11px;
            color: #ff7b1c;
            border-radius: 2px;
            margin-right: 4px;
          }

          .roomItemText {
            font-size: 16px !important;
            line-height: 24px;
          }
        }

        .timeText {
          font-size: 14px;
          color: #999999;
          margin: 8px 0 10px 0;
        }

        .tips {
          font-size: 12px;
          color: #999999;
        }
      }

      .btnItem {
        width: 88px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid #ff5e51;
        font-size: 14px;
        color: #ff5e51;
        text-align: center;
        line-height: 30px;
        margin-left: 20px;
        margin-top: 35px;
        cursor: pointer;
      }
    }
  }
}

.noMsg {
  text-align: center;
  line-height: 400px;
}</style>